<template>
  <div>
    <div
      v-if="loading"
      class="text-center d-flex justify-content-center align-items-center loader"
    >
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card v-else>
      <form-component mode="create" />
    </b-card>
  </div>
</template>

<script>
import {
BCard,
BSpinner,
} from "bootstrap-vue";
import FormComponent from "./components/FormComponent.vue";
export default {
  components: {
    BCard,
    BSpinner,
    FormComponent,
  },
  props: {},
  data() {
    return {
      loading: false
    };
  },  
};
</script>

<style scoped>
.btn-space {
  margin: 2px;
}
.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}
.decline {
  background-color: #ff4f5d1a !important;
  color: #ff4f5d !important;
  border-color: transparent !important;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
}
.send-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);

  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}
.preview {
  border-color: transparent !important;
  background: linear-gradient(315deg, white 100%, white 100%, white 100%);
}
</style>
